import { useTranslation } from 'react-i18next'
import AppspaceIcon from 'src/components/navbar/icons/appspace.icon'
import { BlogIcon } from 'src/components/navbar/icons/blog.icon'
import { BookingServiceIcon } from 'src/components/navbar/icons/booking-service.icon'
import GrowthIcon from 'src/components/navbar/icons/growth.icon'
import { HousiDecorIcon } from 'src/components/navbar/icons/housidecor.icon'
import { NewspaperIcon } from 'src/components/navbar/icons/newspaper.icon'
import { PeopleIcon } from 'src/components/navbar/icons/people.icon'
import { PinIcon } from 'src/components/navbar/icons/pin.icon'
import { SupportIcon } from 'src/components/navbar/icons/support.icon'
import { BriefcaseIcon } from 'src/pages/landing-pages/housi-li/v2/icons/briefcase.icon'

export interface NavbarConfigItem {
  id: string
  title: string
  options: OptionsNavbarInterface[]
}

interface OptionsNavbarInterface {
  id: string
  label: string
  path: string
  Icon: (props: any) => JSX.Element
}

export function NavbarItemsConfig() {
  const { t } = useTranslation('components/nav')

  const navbarItemsData: NavbarConfigItem[] = [
    {
      id: 'investidor',
      title: t('components/nav:INVESTORS-PERSONA.investor'),
      options: [
        {
          id: 'housi-decor',
          label: t('components/nav:INVESTORS-PERSONA.decor'),
          path: 'https://housi.com/decor',
          Icon: HousiDecorIcon,
        },
        {
          id: 'housi-vendas',
          label: t('components/nav:INVESTORS-PERSONA.sales'),
          path: 'https://housi.com/vendas',
          Icon: GrowthIcon,
        },
        {
          id: 'relacionamento-investidor',
          label: t('components/nav:INVESTORS-PERSONA.relationship'),
          path: 'https://api.whatsapp.com/send/?phone=5511937181210',
          Icon: SupportIcon,
        },
      ],
    },
    {
      id: 'morador',
      title: t('components/nav:RESIDENTS-PERSONA.residents'),
      options: [
        {
          id: 'hospedagem',
          label: t('components/nav:RESIDENTS-PERSONA.accommodation'),
          path: 'https://housi.com/search',
          Icon: PinIcon,
        },
        {
          id: 'reservas',
          label: t('components/nav:RESIDENTS-PERSONA.reservations'),
          path: 'https://api.whatsapp.com/send?1=pt_BR&phone=5511949454798',
          Icon: BookingServiceIcon,
        },
        {
          id: 'empresas',
          label: t('components/nav:RESIDENTS-PERSONA.company'),
          path: 'https://lps.housi.com/reservas-corporativas/',
          Icon: BriefcaseIcon,
        },
        {
          id: 'beneficios-appspace',
          label: t('components/nav:RESIDENTS-PERSONA.appspace-benefits'),
          path: 'https://appspace.housi.com/',
          Icon: AppspaceIcon,
        },
        {
          id: 'blog-housi',
          label: t('components/nav:RESIDENTS-PERSONA.blog-housi'),
          path: 'https://blog.housi.com',
          Icon: BlogIcon,
        },
        {
          id: 'appspace-no-predio',
          label: t('components/nav:RESIDENTS-PERSONA.appspace-building'),
          path: 'https://appspace.housi.com/sindicos',
          Icon: GrowthIcon,
        },
      ],
    },
    {
      id: 'incorporador',
      title: t('components/nav:DEVELOPER-PERSONA.developer'),
      options: [
        {
          id: 'parceiro-housi',
          label: t('components/nav:DEVELOPER-PERSONA.partner-housi'),
          path: 'https://lps.housi.com/incorporadores/',
          Icon: PeopleIcon,
        },
        {
          id: 'cases-housi',
          label: t('components/nav:DEVELOPER-PERSONA.cases-housi'),
          path: 'https://blog.housi.com/expansao/',
          Icon: NewspaperIcon,
        },
      ],
    },
  ]

  return { navbarItemsData }
}
